body {
  margin: 0;
  padding: 0;
}

.homePage-image {
  background-image: url('https://images.pexels.com/photos/3183187/pexels-photo-3183187.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
  height: 92vh;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

/* .homePage-quote {
  width: 20%;
  margin-top: 0;
  padding-top: 10%;
  padding-left: 3%;
} */

.public-projects-image {
  background-image: url('https://images.pexels.com/photos/1295138/pexels-photo-1295138.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

}

.loginPage {
  background-image: url('https://images.pexels.com/photos/316902/pexels-photo-316902.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
  height: 92vh;
  /* opacity: 0.4; */
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.backerLogin-image {
  background-image: url('https://images.pexels.com/photos/3675083/pexels-photo-3675083.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
  height: 92vh;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.developerLogin-image {
  background-image: url('https://images.pexels.com/photos/3875821/pexels-photo-3875821.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
  height: 92vh;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.developerSignup-image {
  background-image: url('https://images.pexels.com/photos/3573603/pexels-photo-3573603.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

}

.backerSignup-image {
  background-image: url('https://images.pexels.com/photos/3979186/pexels-photo-3979186.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

}


.backer-dashboard-image {
  background-image: url('https://images.pexels.com/photos/3312664/pexels-photo-3312664.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

}

.developer-dashboard-image {
  background-image: url('https://images.pexels.com/photos/6992/forest-trees-northwestisbest-exploress.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
  height: 92vh;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

}

.createProject-image{
  background-image: url('https://images.pexels.com/photos/2835562/pexels-photo-2835562.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
  height: 92vh;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

}


